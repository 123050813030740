/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

require("bootstrap/dist/css/bootstrap.css")

// normalize CSS across browsers
require("./src/styles/normalize.css")
// custom CSS styles
require("./src/styles/global.css")

var smoothscroll = require("smoothscroll-polyfill")

exports.onClientEntry = () => {
  smoothscroll.polyfill()
}
